<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE BUYER PRODUCTION PROCESS DATA</title>
    <section class="content-header">
      <h1>
        Update Buyer Production Process Data
        <br />
        <h4>Please edit the buyer's production process transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Buyer Production Process Transactions</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Production Process</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Batch Code</label>
                  <input
                    type="hidden"
                    readonly
                    v-model="idxprosesprod"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    readonly
                    v-model="kodebatch"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Batch Code"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="idxbuyerorder"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="kodeproses"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="refidxprosesprod"
                    autocomplete="off"
                    class="form-control"
                  />

                  <input
                    type="hidden"
                    readonly
                    v-model="refidxoutgoingd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    readonly
                    v-model="notesprosesprod"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Date</label>
                  <input
                    type="text"
                    v-model="prosesdate"
                    readonly
                    autocomplete="off"
                    class="form-control"
                    placeholder="Process Date"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Results</label>
                  <input
                    type="text"
                    readonly
                    v-model="prosesresult"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Process Results"
                  />
                </div>
                <!-- <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="jumlahbarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div> -->
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="namaproses"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Production Process</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Code</label>
                  <input
                    type="text"
                    readonly
                    v-model="kodebarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name</label>
                  <input
                    type="text"
                    v-model="namabarang"
                    readonly
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number</label>
                  <input
                    type="text"
                    v-model="ordnumb"
                    readonly
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    v-model="prodnumb"
                    readonly
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    readonly
                    v-model="unit"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/proses-produksi">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "edittdata",
  data() {
    return {
      isInputActive: false,
      loading: false,
      prodnumb: "",
      idxprosesprod: "",
      idxbuyerorder: "",
      notesprosesprod: "",
      prosesdate: "",
      prosesresult: "",
      // jumlahbarang: "",
      kodeproses: "",
      kodebarang: "",
      namabarang: "",
      namaproses: "",
      ordnumb: "",
      refidxprosesprod: "",
      refidxoutgoingd: "",
      unit: "",
      kodebatch: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi/getproses_produksibyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprosesprod = resp.data.data.id;
          this.kodebatch = resp.data.data.nomor_produksi;
          this.idxbuyerorder = resp.data.data.id_buyer_order;
          this.kodeproses = resp.data.data.kode_proses;
          this.refidxprosesprod = resp.data.data.ref_id_proses_produksi;
          this.refidxoutgoingd = resp.data.data.id_outgoing;
          this.notesprosesprod = resp.data.data.notes;
          this.prosesdate = resp.data.data.tanggal_proses;
          this.prosesresult = resp.data.data.hasil_proses;
          // this.jumlahbarang = resp.data.data.qty_barang;
          this.namaproses = resp.data.data.nama_proses;
          this.kodebarang = resp.data.data.kode_barang;
          this.namabarang = resp.data.data.nama_barang;
          this.ordnumb = resp.data.data.nomor_order;
          this.prodnumb = resp.data.data.nomor_produk;
          this.unit = resp.data.data.unit;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var jml = this.jumlahbarang;
      var idx = this.idxprosesprod;
      if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produksi: this.kodebatch,
          kode_proses: this.kodeproses,
          notes: this.notesprosesprod,
          tanggal_proses: this.prosesdate,
          kode_user: kodeuser,
          hasil_proses: this.prosesresult,
          qty_barang: this.jumlahbarang,
          id_outgoing: this.refidxoutgoingd,
          ref_id_proses_produksi: this.refidxprosesprod,
          id_buyer_order: this.idxbuyerorder
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIUpdate =
          this.$apiurl + "proses_produksi/updateproses_produksi/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "prosesproduksiheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
